define("discourse/plugins/discourse-tooltips/connectors/topic-link/d-tooltips", ["exports", "@ember/template", "float-kit/components/d-tooltip", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _template, _dTooltip, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const triggers = {
    mobile: ["hover"],
    desktop: ["hover"]
  };
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DTooltip @triggers={{triggers}} @untriggers={{triggers}} @placement="bottom">
      <:trigger>
        {{yield}}
      </:trigger>
      <:content>
        <div class="d-tooltip-content">
          {{htmlSafe @outletArgs.topic.excerpt}}
        </div>
      </:content>
    </DTooltip>
  
  */
  {
    "id": "rer5DuKu",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@triggers\",\"@untriggers\",\"@placement\"],[[32,1],[32,1],\"bottom\"]],[[\"trigger\",\"content\"],[[[[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[10,0],[14,0,\"d-tooltip-content\"],[12],[1,\"\\n        \"],[1,[28,[32,2],[[30,1,[\"topic\",\"excerpt\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@outletArgs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-tooltips/connectors/topic-link/d-tooltips.js",
    "scope": () => [_dTooltip.default, triggers, _template.htmlSafe],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "d-tooltips"));
});