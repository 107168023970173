define("discourse/plugins/discourse-tooltips/mixins/topic-hover-extension", ["exports", "@ember/runloop", "jquery", "discourse/plugins/discourse-tooltips/discourse/lib/event-from"], function (_exports, _runloop, _jquery, _eventFrom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hoverExtension = hoverExtension;
  // TODO: remove this file once raw-hbs topic list is removed
  // Search keyword: discourse.hbr-topic-list-overrides

  function cleanDom() {
    (0, _jquery.default)(".d-tooltip").remove();
  }
  function cancel() {
    cleanDom();
  }
  function renderTooltip($this, text) {
    if (!text) {
      return;
    }
    $this.after(`<div class='d-tooltip'><div class='d-tooltip-pointer'></div><div class='d-tooltip-content'>${text}</div></div></div>`);
    let $dTooltip = (0, _jquery.default)(".d-tooltip");
    let tooltipWidth = $dTooltip.outerWidth();
    let elementWidth = $this.width();
    let elementHeight = $this.height();
    let elementPos = $this.position();
    let elementX = elementPos.left;
    let y = elementPos.top + elementHeight;
    let x = elementX + elementWidth / 2 - tooltipWidth / 2;

    // make sure left side of the tooltip is not out of the screen
    let $mainLink = $this.hasClass("main-link") ? $this : $this.parents(".main-link");
    let mainLinkLeftOffset = $mainLink.offset().left;
    if (mainLinkLeftOffset + x < 0) {
      x = elementX;
    }
    $dTooltip.css({
      left: `${x}px`,
      top: `${y}px`
    });
    $dTooltip.fadeIn(200);
  }
  function hoverExtension(selector) {
    return {
      pluginId: "discourse-tooltips",
      didInsertElement() {
        this._super(...arguments);
        cancel();
        this.element.classList.add("discourse-tooltips-legacy");
        (0, _jquery.default)(this.element).on("mouseenter.discourse-tooltips", selector, e => {
          if ((0, _eventFrom.eventFrom)(e) !== "mouse") {
            return;
          }

          // eslint-disable-next-line ember/jquery-ember-run
          cancel();
          const topicId = parseInt(e.target.closest("[data-topic-id]").dataset.topicId, 10);
          const topic = this.topics.find(t => t.id === topicId);
          return renderTooltip((0, _jquery.default)(e.target), topic.excerpt);
        });
        (0, _jquery.default)(this.element).on("mouseleave.discourse-tooltips", selector, e => {
          if ((0, _eventFrom.eventFrom)(e) !== "mouse") {
            return;
          }
          (0, _runloop.run)(() => cleanDom());
        });
      },
      willDestroyElement() {
        this._super(...arguments);
        cancel();
        (0, _jquery.default)(this.element).find(selector).off("mouseenter.discourse-tooltips, mouseleave.discourse-tooltips", selector);
      }
    };
  }
});